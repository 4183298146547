<template>
	<div class="home">
		<boa></boa>
	</div>
</template>

<script>
import boa from '@/components/pages/report/boa/boaRequest.vue';

export default {
	components: {
		'boa': boa,
	}
};
</script>

<style>

</style>
